<script>
// import Swal from 'sweetalert2'

export default {
    props: {
        activities: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            // activities: []
        }
    },
    /* setup() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        return { Toast }
    }, */
    async created() {
        
    },
}
</script>

<template>
    <div class="card">
        <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Ongoing Agent Convertation</h4>
            <!-- <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                    <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <span class="text-muted fs-18"><i class="mdi mdi-dots-vertical"></i></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" href="#">Edit</a>
                        <a class="dropdown-item" href="#">Remove</a>
                    </div>
                </div>
            </div> -->
        </div><!-- end card header -->
        <div class="card-body pt-0">
            <ul class="list-group list-group-flush border-dashed" v-if="activities.length > 0">
                <li class="list-group-item ps-0" v-for="(item , index) of activities" :key="index">
                    <div class="row align-items-center g-3">
                        <div class="col-auto">
                            <div class="avatar-sm p-1 py-2 h-auto bg-light rounded-3 shadow">
                                <div class="text-center">
                                    <h5 class="mb-0">{{item.date}}</h5>
                                    <div class="text-muted">{{item.weekDay}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <h5 class="text-muted mt-0 mb-1 fs-13">{{item.time}}</h5>
                            <a href="#" class="text-reset fs-14 mb-0">{{item.caption}}</a>
                        </div>
                        <div class="col-sm-auto">
                            <div class="avatar-group">
                                <div class="avatar-group-item shadow" v-for="(subitem, index) of item.subItem.slice(0, 5)"
                                    :key="index">
                                    <a href="javascript: void(0);" class="d-inline-block" data-bs-toggle="tooltip"
                                        data-bs-placement="top" :title="subitem.name" data-bs-original-title="Stine Nielsen">
                                        <img :src="`${subitem.img}`" alt="" class="rounded-circle avatar-xxs">
                                    </a>
                                </div>

                                <div class="avatar-group-item shadow">
                                    <a href="javascript: void(0);">
                                        <div class="avatar-xxs">
                                            <span :class="`avatar-title rounded-circle ${item.bgcolor} text-white`">
                                                {{item.imgNumber}}
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->
                </li><!-- end -->
            </ul><!-- end -->
            <ul class="list-group list-group-flush border-dashed" v-else>
                <li>No Data</li>
            </ul>
            <!-- <div class="align-items-center mt-2 row g-3 text-center text-sm-start">
                <div class="col-sm">
                    <div class="text-muted">Showing<span class="fw-semibold"> 5</span> of <span
                            class="fw-semibold">{{ this.activities.length }}</span> Results
                    </div>
                </div>
                <div class="col-sm-auto">
                    <ul
                        class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                        <li class="page-item disabled">
                            <a href="#" class="page-link">←</a>
                        </li>
                        <li class="page-item active">
                            <a href="#" class="page-link">1</a>
                        </li>
                        <li class="page-item">
                            <a href="#" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                            <a href="#" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                            <a href="#" class="page-link">→</a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div><!-- end card body -->
    </div><!-- end card -->
</template>