<script>
import dayjs from 'dayjs';

export default {
  props: {
    dealsStatus: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  setup() {
    
    let month = (dayjs().$M + 1) <= 9 ? "0"+(dayjs().$M + 1) : (dayjs().$M + 1);
    let days = (dayjs().$D) <= 9 ? "0"+(dayjs().$D) : dayjs().$D;
    let sevenDayAgo = dayjs().$D - 13;
    let formatSevendayAgo = sevenDayAgo <= 9 ? "0"+sevenDayAgo : sevenDayAgo;

    const min = `${dayjs().$y}-${month}-${formatSevendayAgo}`;
    const max = `${dayjs().$y}-${month}-${days}`;

    return {min, max};
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Leads Recive Of Day</h4>
      <div class="flex-shrink-0">
        <input v-on:change="$emit('filterDate')" type="date" :min="min" :max="max" class="form-control dateFilter" />
      </div>
    </div>
    <!-- end card header -->

    <div class="card-body">
      <div class="table-responsive table-card">
        <table
          class="table table-borderless table-hover table-nowrap align-middle mb-0"
        >
          <thead class="table-light">
            <tr class="text-muted">
              <th scope="col">Agent</th>
              <th scope="col" style="width: 16%">Unassign</th>
              <th scope="col" style="width: 16%">Assigned</th>
            </tr>
          </thead>

          <tbody v-if="dealsStatus.length > 0">
            <tr v-for="(item, index) of dealsStatus" :key="index"> 
              <td>
                <!-- <img
                  :src="`${item.img}`"
                  alt=""
                  class="avatar-xs rounded-circle me-2 shadow"
                /> -->
                <a href="#javascript: void(0);" class="text-body fw-medium"
                  >{{item.Name}}</a
                >
              </td>
              <td class="text-center">
                <span class="badge badge-soft-primary p-2">{{item.UnassignCount}}</span>
              </td>
              <td class="text-center">
                <span class="badge badge-soft-primary p-2">{{item.CustomerCount}}</span>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td>Belum ada leads baru</td>
            </tr>
          </tbody>
          <!-- end tbody -->
        </table>
        <!-- end table -->
      </div>
      <!-- end table responsive -->
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>
