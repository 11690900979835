<script>
import axios from "axios";
import Swal from "sweetalert2";
import { CountTo } from "vue3-count-to";

export default {
  data() {
    return {
      crmWidgets: []
    }
  },
  setup() {

    var secure = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt")
    var url = process.env.VUE_APP_URL_API+"agent-api/dashboard"+secure
    const getDashboard = axios.get(url).then(res => {
      return res.data;
    }).catch(err => {
      return err;
    })

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    return {
      getDashboard,
      Toast
    };
  },
  async created() {
    const dashboard = await this.getDashboard;
    if(dashboard.Meta.Code !== 200){
      this.Toast.fire({
        icon: 'error',
        title: dashboard.Meta.Message
      })
    }

    this.crmWidgets = [
      {
        id: 1,
        label: "Campaign",
        badge: "ri-arrow-up-circle-line text-success",
        icon: "ri-space-ship-line",
        counter: dashboard.Data.CampainCount,
        decimals: 0,
        suffix: "",
        prefix: "",
      },
      {
        id: 2,
        label: "Customer",
        badge: "ri-user-3-line text-success",
        icon: "ri-user-3-line",
        counter: dashboard.Data.CustomerCount,
        decimals: 1,
        suffix: "",
        prefix: "",
      },
      {
        id: 3,
        label: "Agent",
        badge: "ri-user-2-line text-danger",
        icon: "ri-user-2-line",
        counter: dashboard.Data.AgentCount,
        decimals: 2,
        suffix: "",
        prefix: "",
      },
    ]
  },
  components: {
    CountTo
  }
};
</script>

<template>
  <div class="card crm-widget">
    <div class="card-body p-0">
      <div class="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
        <div class="col" v-for="(item, index) of crmWidgets" :key="index">
          <div class="py-4 px-3">
            <h5 class="text-muted text-uppercase fs-13">
              {{ item.label }}
              <i :class="`${item.badge} fs-18 float-end align-middle`"></i>
            </h5>
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="`${item.icon} display-6 text-muted`"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-0">
                  {{item.prefix}}<count-to :startVal='0' :endVal='item.counter' :duration='5000'></count-to>{{item.suffix}}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>
