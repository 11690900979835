<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import Widget from "./widget.vue";
import agentFirstResponse from "./agent-first-response.vue";
import UpcomingActivity from "./activities.vue";
import dealsStatus from "./deals-status.vue";
import axios from "axios";

export default {
  page: {
    title: "CRM",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "CRM",
      activities: [],
      todayLeads: [],
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "CRM",
          active: true,
        },
      ],
    };
  },
  methods: {
    async filterTodayLeads(){
      let dateFilter = document.querySelector(".dateFilter").value;
      let secureParam = "&AccessToken="+localStorage.getItem("jwt")+"&ClientID="+process.env.VUE_APP_CLIENT_ID;
      let filterParam = "?date="+dateFilter;

      let url = process.env.VUE_APP_URL_API+"agent-api/filterTodayrecive"+filterParam+secureParam;
      const response = await axios(url);

      if(response.data.Meta.Code === 200){
        let data = response.data.Data.TodayLedsRecived;
        this.todayLeads = data;
      }
    },
  },
  async mounted(){
    console.log("masuk mounted index");
    this.initSocket();
    if(!localStorage.getItem("ChatData")){
      await this.syncChatRoomsWa();
    }
    var secure = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt")
    var url = process.env.VUE_APP_URL_API+"agent-api/dashboard"+secure
    const dashboard = axios.get(url).then(res => {
        return res.data;
    }).catch(err => {
        return err;
    })

    let response_dashboard = await dashboard;
    if(response_dashboard.Meta.Code !== 200){
      response_dashboard = {
        Data: {
          Ongoing: [],
          TodayLedsRecived: [],
          CustomerCount: 0
        }
      }
    }
    localStorage.setItem("ContactLength", response_dashboard.Data.CustomerCount);

    const ongoing = response_dashboard
    for (let _a = 0; _a < ongoing.Data.Ongoing.length; _a++) {
        const item = ongoing.Data.Ongoing[_a];

        let customer = [];
        for (let _i = 0; _i < item.Customer.length; _i++) {
            const cust = item.Customer[_i];
            /* const waGateway = await axios.post(process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/chat-rooms", {
                phones: [cust.Phone],
                kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
            }, {
                headers: {
                    ClientID: "abcd1234"
                }
            }); */
            var photo = cust.Avatar ? cust.Avatar.Original : "";
            /* if(waGateway.data.data[0].photo !== null){
                photo = waGateway.data.data[0].photo
            } */


            customer.push(
                {id: cust.ID, name:cust.Name, img: photo },
            )
        }

        const dateUpdate = new Date(item.Updated);

        this.activities.push({
            id: item.ID,
            date: dateUpdate.getDate()+"/"+(dateUpdate.getMonth()+1),
            weekDay: dateUpdate.getFullYear(),
            time: item.Name,
            caption: item.Name+" ongoing convertation "+item.Customer.length+" Customer",
            subItem: customer,
            imgNumber: item.Customer.length,
            bgcolor: "bg-info"
        })
    }

    const leads = response_dashboard
    const todayRecive = leads.Data.TodayLedsRecived
    this.todayLeads = todayRecive;
  },
  components: {
    Layout,
    PageHeader,
    Widget,
    agentFirstResponse,
    UpcomingActivity,
    dealsStatus
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-xl-12">
        <Widget />
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-12">
        <agentFirstResponse />
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-md-6">
          <UpcomingActivity :activities="activities" />
      </div>
      <div class="col-xxl-6 col-md-6">
          <dealsStatus :dealsStatus="todayLeads" @filter-date="filterTodayLeads" />
      </div>
    </div>
  </Layout>
</template>
